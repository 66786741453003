import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { backgroundImage, userLogo } from "../assets";
import { getStorageData,setStorageData } from "framework/src/Utilities";
import storage from "../../../../framework/src/StorageProvider.web";


export interface ProfileDetails {
  id: number;
  country: string;
  address: string;
  city: string;
  postal_code: string;
  account_id: number;
  photo: string | undefined;
  background_color: string;
  name: string;
  website_link: string;
  about: string;
  expertise_category: [];
  skills: [];
  languages: [];
  min_per_hour: number;
  max_per_hour: number;
  dribble: string;
  instagram: string;
  linkedin: string;
  member_since: string;
  followers_count: number;
  following_count: number;
  work_experience: {
    company_name: string;
    role:string;
    duration: string;
    total_years_worked:string ;
  }[];
  profile_cover_image: string | undefined;
  company_details:any
 
}


// Customizable Area End

export const configJSON = require("../config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  currentCountryCode: any;
  data: any[];
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  expertiseTempData:any[];
  skillsTempData:any[];
  profileImageData:any;
  selectedFile: File | null;
  combineImg:string | null | unknown;
  saveImage:string | '' |unknown;
  saveProfileImage:string |'' |unknown
  UserInfoData:any;
  userName: string;
  userRole:string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  btnTextSaveChanges: string;

  apiCallMessageUpdateProfileRequestId: any;
  
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userProfileImageGetApiCallId:string = "";
  userProfileFollowApiCallId:string=""
  profileViewApiCallId:string=""
  userProfileUnFollowApiCallId:string = ""
  deleteProfileImageGetApiCallId:string = "";
  updateImageAPICallId:string = "";
  userAttr: any;
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      profileImageData:  {
      
            profile_details: {
                id: 0,
                country: "",
                address: "",
                city: "",
                postal_code: "",
                account_id: 0,
                photo: "",
                background_color: "",
                name: "",
                website_link: "",
                about: "",
                expertise_category: [],
                skills: [],
                languages: [],
                min_per_hour: 0,
                max_per_hour: 0,
                dribble: "",
                instagram: "",
                linkedin: "",
                member_since: "",
                followers_count: 0,
                following_count: 0,
                work_experience: [{
                  company_name: '',
                role:'',
                duration: '',
                total_years_worked:'' 
                }],
                profile_cover_image: "",
                company_details:""
            },
            educations: [
                {
                    id: 0,
                    qualification: "",
                    year_from: "",
                    year_to: "",
                    description: ""
                }
            ],
            profile_views: 0,
            profile_likes: 0 ,
            is_followed : false      
    },

    selectedFile:null,
    combineImg:null,
    saveImage: '',
    saveProfileImage:'',
      UserInfoData:"",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      expertiseTempData:["Graphic Design","UX Design","UI design","UI design","UI design","UI design","UI design"],
      skillsTempData:["Illustrator","Photoshop","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's","NFT's"],
     
        userName: "",
        userRole:''

    };
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    this.userProfile(message)
 if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }
   

    // Customizable Area End
  }



  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // Customizable Area Start

  userProfile = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const profileImageJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.userProfileImageGetApiCallId) {
        this.setState({
          profileImageData: profileImageJson.profile_data,
        })
      }
      if(apiRequestCallId===this.userProfileFollowApiCallId){
        if(!profileImageJson.error){
          this.setState(prevState => ({
            profileImageData: {
              ...prevState.profileImageData,
              is_followed: !prevState.profileImageData.is_followed
            }
          }));
        }
      }
      if(apiRequestCallId === this.userProfileUnFollowApiCallId){
        if(!profileImageJson.error){
          this.setState(prevState => ({
            profileImageData: {
              ...prevState.profileImageData,
              is_followed: !prevState.profileImageData.is_followed
            }
          }));
        }
      }
    }
  }
 
  getUserProfileImage = async () => {
    const tokens = await getStorageData("userInfo");
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    this.setState({UserInfoData:tokens,userRole:accountInfo.user_role})
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
   const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileImageGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${accountInfo.account_id}`  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile 
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  timer: ReturnType<typeof setTimeout> | null = null;
  async componentDidMount() {  
    setStorageData("isPublicProfile",true)
    this.getUserProfileImage();  
    this.getUserName();
    this.timer = setTimeout(() => {
      this.updateProfileView();
    }, 3000);
  }

  async componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  updateProfileView = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const body =
    {
      "account_id": accountInfo?.account_id
    }   
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileViewApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileViewApiEndPoint  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  imageUrl = () => {
    const profileCoverImage = this.state.profileImageData?.profile_details?.profile_cover_image;
    const imageUrl = this.state.saveImage || (profileCoverImage ? `${this.baseUrlStirng}${profileCoverImage}` : backgroundImage);
    return imageUrl;
  }
  imageProfile =() =>{
    const profilePhoto = this.state.profileImageData?.profile_details?.photo;
    const profileImageUrl = this.state.saveProfileImage || (profilePhoto ? `${this.baseUrlStirng}${profilePhoto}` : userLogo);
    return profileImageUrl
  }
  imgproUpdate = () => {
    const profileCoverImage3 =  this.state.profileImageData?.profile_details?.photo;
    const profileupdateImageUrl =  this.state.combineImg ||  (profileCoverImage3 ? `${this.baseUrlStirng}${profileCoverImage3}` : userLogo) 
    return profileupdateImageUrl
  }

  getUserName = async () => {
    let userProfileName = await getStorageData("userInfo");

    userProfileName = JSON.parse(userProfileName);
    
    userProfileName = userProfileName.data.attributes.first_name + this.handleNullvalue(userProfileName.data.attributes.last_name )
     
     if (userProfileName) {
       this.setState({ userName: userProfileName});
     };
   
  }
  handleNullvalue = (value: string | null)=>{
    if(value === null){
      return "";
    }else{
      return value;
    }
  };
  websideShow =() => {
    const websiteName =this.state.profileImageData.profile_details?.website_link ? this.state.profileImageData.profile_details.website_link:"--" 
    return websiteName
  }
  memberSince = () => {
   const memberName = this.state.profileImageData.profile_details.member_since ? this.state.profileImageData.profile_details.member_since:"--" 
    return memberName
  }
  showLoaction = () => {
    const { city, country } = this.state.profileImageData.profile_details || {};
    if (!city || !country) {
      return "Add Location";
    }
    const location = city === country ? city : `${city}, ${country}`;
    return location;
  }

  handleFollowAndUnfollow = () => {
    this.state.profileImageData.is_followed ? this.handleUnFollow() : this.handleFollow()
  }
  handleUnFollow = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileUnFollowApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnfollowApiEndPoint+ `/${accountInfo?.account_id}` )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteUserType 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleFollow = async () => {
    const userProfile = await getStorageData("userInfo",true);
    const PublicId = await getStorageData("PublicId");
    let accountInfo = JSON.parse(PublicId)
    const body = {
      "data": {
       "attributes": {
           "account_id":  accountInfo?.account_id
       }
      } 
   }
    const headers = {
      "Content-Type": "application/json",
      token: userProfile.meta.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileFollowApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnfollowApiEndPoint  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo 
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  showAboutInfo  = () => {
   const showAbout = this.state.profileImageData?.profile_details.about != "" ? this.state.profileImageData.profile_details.about:"--" 
  
   return showAbout
  }
  CompanyName = () =>{
    let compananyName = this.state.profileImageData.profile_details.company_details?.data?.attributes.company_name
    
    const showCompanyName = compananyName? compananyName: 'CompanyName'
    return showCompanyName
  }
  showIndustry = () =>{
    let Industry  = this.state.profileImageData.profile_details.company_details?.data?.attributes.industry.name
    const shouldShowIndustry = Industry? Industry:"No Information"
    return shouldShowIndustry
  }
  showDesignation = () =>{ 
    let designationName = this.state.profileImageData.profile_details.company_details?.data?.attributes.designation.name
    const shouldShowDesignation = designationName? designationName:"No Information"
    return shouldShowDesignation
  } 



  aboutRoleShow = () =>{
    const showRoles = this.state.userRole == "designer" ? "About Designer" : "About Client";
    return showRoles;
  }
  profileView = () => {
   const  showProfile = this.state.profileImageData.profile_views?this.state.profileImageData.profile_views: '-'
    return  showProfile
  }
  JobsPosted =() =>{
    const jobsPosted = this.state.profileImageData.opportunity_count ? this.state.profileImageData.opportunity_count: 0
    return jobsPosted
  }
  activeOpportunities = () =>{
    const activeOpportunities = this.state.profileImageData.open_opportunity_count ? this.state.profileImageData.open_opportunity_count: 0
    return activeOpportunities}
    
  showLike = () => {
    const likefollow = this.state.profileImageData.profile_likes?this.state.profileImageData.profile_likes: '-'
    return likefollow
  }
  avgRate = () => {
   const showAvfRate = this.state.profileImageData.profile_details.min_per_hour + this.state.profileImageData.profile_details.max_per_hour / 2 
   const AvgRates =  showAvfRate ? `₹${showAvfRate}` :'--'
   return this.state.profileImageData.profile_details?.average_rate ?? 0
  }
  showFollowing =  () => {
    const following =this.state.profileImageData.profile_details?.following_count?this.state.profileImageData.profile_details?.following_count: 0
    return following
  }
  followers = () => {
  const showFollower =this.state.profileImageData.profile_details?.followers_count?this.state.profileImageData.profile_details?.followers_count: 0
  return showFollower
}
totalWorkExp = () => {
  const workExp  =this.state.profileImageData.profile_details.work_experience
    ? this.state.profileImageData.profile_details.work_experience.length
    : 0
    return workExp
}

formatYears = (yearFrom:string|number, yearTo:string|number) => {
  return yearFrom && yearTo ? `${yearFrom} - ${yearTo}` : '';
}
  handleNavigationToChatScreen = async () => {
    storage.set("navigateUserId",JSON.stringify(this.state.profileImageData.sendbird_data))
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Chat");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }
  // Customizable Area End

}
