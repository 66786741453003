import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import { rightIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "8px",
  borderBottom: "#535353 1px solid",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#FFF2E3",
  transition: "color 0.3s",
  "&.active": {
    background: "#111111",
  },
}));
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id='parent-container' style={webStyle.parentContainer}>
        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.navigationStyle}><NavigationMenu {...this.props} activeTab='user' /></Box>
        </Box>
        <Box style={{display:"flex"}}>
        <Box style={webStyle.leftContainer}>
          <Box style={webStyle.userDetail}>
            <Box style={webStyle.userStyle}>
                {this.state.userDetail.data.attributes.profile_image ?
                  <img style={webStyle.profileStyle} src={configJSON.baseURL.baseURL + this.state.userDetail.data.attributes.profile_image} alt="" />
                  : <Box style={{ ...webStyle.profileStyle, backgroundColor: this.state.userDetail.data.attributes.background_color }}>  </Box>
                }
                <Box style={webStyle.userHeaderStyle}>
                  <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                      {this.state.userDetail.data.attributes.first_name}
                  </Typography>
                  <Typography style={{...webStyle.userNameStyle,color:"#535353",fontSize:"16px"}}>
                      Online
                  </Typography>
                </Box>
            </Box>
              <Box style={{ marginTop: "26px" }}>
                <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                  Settings
                </Typography>
                <Box style={webStyle.menuStyle}>
                  {this.tabs.map((tab: string, index: number) => (
                    <StyledTypography
                      data-test-id = "active-tab-id"
                      key={index}
                      className={this.state.activeTab === tab ? "active" : ""}
                      onClick={() => this.handleActiveTab(tab)}
                    >
                      {tab}
                    </StyledTypography>
                  ))}
                  <Typography style={webStyle.logOutStyle}>
                    Log Out
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Box>
          <Box style={webStyle.subContainer}>
            <Box style={webStyle.subTextContainer}>
              <Typography
                style={webStyle.headerText}>
                Password & Security
              </Typography>
              <Typography
                style={webStyle.mainHeaderText}>
                Login & Recovery
              </Typography>
              <Typography
                style={webStyle.subHeaderText}>
                Manage your passwords, login preferences and recovery methods
              </Typography>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.rightBoxStyle}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Username:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.user_name}
                  </Typography>
                </Box>
                <Box style={{ ...webStyle.rightBoxStyle,marginLeft: "110px" }}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Email id:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.email}
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.passwordContainer}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Change Password
                  </Typography>
                  <img style={webStyle.passwordImage} src={rightIcon} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  parentContainer:{ backgroundColor: "#111111" },
  leftContainer:{
    backgroundColor:"#171717",width:"500px",display:"flex",justifyContent:"center"
  },
  userDetail:{
    width:"413px"
  },
  userStyle:{
    padding:"16px 0px",display:"flex",alignItems:"center",marginTop:"100px",width:"100%",height:"80px",backgroundColor:"#111111",borderRadius:"24px"
  },
  userHeaderStyle:{
    marginLeft:"16px",display:"flex",alignItems:"flex-start",flexDirection:"column" as "column"
  },
  commonTextStyle:{
    color:"#FFF2E3",fontSize:"20px"
  },
  menuStyle:{
    display:"flex",flexDirection:"column" as "column",gap:"16px",padding:"16px 0px"
  },
  logOutStyle:{
    width:"80px",cursor:"pointer",padding:"10px",fontFamily: "Inter", fontSize: "20px", fontWeight: 400, lineHeight: "24px", color: "#FF7878"
  },
  userNameStyle:{
    fontFamily:"Inter",fontWeight:600,lineHeight:"24px"
  },
  profileStyle:{
    marginLeft: "16px", height: "70", width: "70", borderRadius: "50%"
  },
  subContainer:{
    height: "100vh", width: "calc(100% - 500px)"
  },
  subTextContainer:{
    marginLeft: "60px", marginTop: "100px", marginRight: "60px"
  },
  headerText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "36px", letterSpacing: "0.38px"
  },
  subHeaderText:{
    color: "#8C8C8C", fontFamily: "Inter", fontWeight: 600, fontSize: "14px", letterSpacing: "0.38px"
  },
  mainHeaderText:{
    marginTop: "40px", marginBottom: "12px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "24px", letterSpacing: "0.38px"
  },
  rightContainer:{
    marginTop: "35px", paddingLeft: "16px", borderRadius: "16px", backgroundColor: "#171717", width: "100%", height: "72px", display: "flex"
  },
  passwordContainer:{
    width:"100%",display: "flex", alignItems: "center",justifyContent:"space-between"
  },
  passwordImage:{
    marginRight:"16px",cursor:"pointer"
  },
  rightBoxStyle:{
    display: "flex", alignItems: "center"
  },
  rightMainText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 500, fontSize: "20px"
  },
  rightTextStyle:{
    marginLeft: "10px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "24px", letterSpacing: "0.38px"
  },
  mainContainer:{ 
    display: 'flex',
  flexDirection: 'column' as 'column',
},
  navigationStyle:{
    position: 'fixed' as 'fixed',
    width: '100%',
    zIndex: 999,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
