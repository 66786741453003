import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import ServiceController, { configJSON, Props } from "./ServicesController";
import CircularProgress from '@mui/material/CircularProgress';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const MainContainer = styled(Box)({
    marginTop: "25px",
    padding: "0px 24px",
    height: "456px",
    width: "96%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "50px",
})
const MainContainerService = styled(Box)({
    marginTop: "25px",
    padding: "0px 24px",
    width: "96%",
    display: "flex",
    flexDirection:"column",
})
const ServiceListStyleButton = styled(Box)({
    marginBottom: "16px", backgroundColor: "white",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    width: "145px",
    height: "44px",
    borderRadius: "7px",
    color: "#121212",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "0.3799999952316284px", cursor: "pointer"
})
const ServiceListStyle = styled(Box)({
    display:"grid",
            gridTemplateColumns:"repeat(2,1fr)",
})
const ListMainBox = styled(Box)({
    marginBottom:"26px",display:"flex",width:"89%",borderRadius:"12px",padding:"20px",backgroundColor:"#171717",flexDirection:"column"
})
const CoverImgStyle = styled("img")({
    width:"100%",height:"274px",borderRadius:"12px"
})
const ProfileImageStyle = styled("img")({
    width:"86px",height:"86px",borderRadius:"50%"
})
const ServiceNameStyle = styled(Typography)({
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.3799999952316284px",
})
const RateBoxStyle = styled(Box)({
    display:"flex",gap:"12px",alignItems:"center",marginTop:"16px"
})
const SkillTagsStyle = styled(Typography)({
    padding:"0px 10px",display:"flex",alignItems:"center",height:"29px",color:"#FFF2E3",fontFamily:"inter",fontSize:"14px",fontWeight:600,lineHeight:"16.94px",backgroundColor:"#2F2F2F",borderRadius:"13.41px"
})
const DividerStyle = styled(Divider)({
    marginTop:"26px", marginBottom:"26px",backgroundColor: "rgba(84, 84, 84, 1)"
})
const SkillsStyle = styled(Box)({
    display:"flex",gap:"10px",flexWrap: "wrap",height: "115px",
    overflowY: "auto",
    scrollbarWidth: "none"
})
const RateTypeStyle = styled(Typography)({
    padding:"0px 10px",display:"flex",alignItems:"center",height:"29px",color:"#FFB9C7",fontFamily:"inter",fontSize:"14px",fontWeight:600,lineHeight:"16.94px",backgroundColor:"#2F2F2F",borderRadius:"13.41px"
})
const RateStyle = styled(Typography)({
    height:"29px",display:"flex",alignItems:"center",padding:"0px 10px",color:"#1CBAE0",fontFamily:"inter",fontSize:"14px",fontWeight:600,lineHeight:"16.94px",backgroundColor:"#2F2F2F",borderRadius:"13.41px"
})
const ListMainBoxStyle = styled(Box)({
    display:'flex',alignItems:"center",marginTop:"26px"
})
const LoaderStyle = styled(Box)({
    width: "100%",
    height: "20rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
})
const MainBox1 = styled(Box)({
    display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
})
const MainTypography1 = styled(Typography)({
    fontWeight: 600, lineHeight: "24.2px", fontSize: "20px", color: "#FFF2E3"
})
const SubTypography1 = styled(Typography)({
    fontWeight: 400, fontSize: "16px", lineHeight: "22.08px", color: "#FFF2E3"
})
const GradientTextButton = styled(Button)({
    position: 'relative',
    background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    padding: 0,
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24.2px",
    textTransform: "none",
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '2px',
        width: '100%',
        background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
        borderRadius: '2px',
    },
});
// Customizable Area End

export default class Services extends ServiceController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderServiceList = () =>(
    this.state.services.length === 0 ? (
        <MainContainer>
            <MainBox1>
                <MainTypography1>Your Service Hub Awaits</MainTypography1>
                <SubTypography1>
                    Service Hub Empty! Add a service now. Begin customizing your offerings
                </SubTypography1>
                <SubTypography1>
                    and creating a tailored service portfolio instantly.
                </SubTypography1>
            </MainBox1>
            {
                !this.isPublic &&
            
            <Box>
                <GradientTextButton
                    data-test-id="navigate-addService"
                    onClick={() => this.handleActiveTab("AddService")}
                >
                    Add Service
                </GradientTextButton>
            </Box>}
        </MainContainer>
    ) : (
        <MainContainerService>
            {!this.isPublic &&
            <ServiceListStyleButton
                data-test-id="add-new-service"
                onClick={() => this.handleActiveTab("AddService")}
            >
                Add Service
            </ServiceListStyleButton>}
            <ServiceListStyle>
                {this.state.services.slice(0, 5).map((item) => (
                    <ListMainBox key={item.id}>
                        <CoverImgStyle
                            alt="cover image"
                            src={`${configJSON.baseURL.baseURL}${item.attributes.cover_image}`}
                        />
                        <ListMainBoxStyle>
                            <Box
                                style={{
                                    width: "86px",
                                    height: "86px",
                                    borderRadius: "50%",
                                    marginRight: "26px",
                                    backgroundColor:
                                        item.attributes.account_details.profile_photo === null
                                            ? item.attributes.account_details.background_color
                                            : "none",
                                }}
                            >
                                {item.attributes.account_details.profile_photo !== null && (
                                    <ProfileImageStyle
                                        alt=""
                                        src={configJSON.baseURL.baseURL+item.attributes.account_details.profile_photo}
                                    />
                                )}
                            </Box>
                            <Box>
                                <ServiceNameStyle
                                    style={{
                                        fontSize: "20px",
                                        color: "#FFF2E3",
                                    }}
                                >
                                    {item.attributes.service_name}
                                </ServiceNameStyle>
                                <ServiceNameStyle
                                    style={{
                                        fontSize: "16px",
                                        color: "#8C8C8C",
                                    }}
                                >
                                    {item.attributes.account_details.name}
                                </ServiceNameStyle>
                                <RateBoxStyle>
                                    <RateStyle>
                                        ₹{item.attributes.minimum_rate} -{" "}
                                        {item.attributes.maximum_rate}
                                    </RateStyle>
                                    <RateTypeStyle>{item.attributes.duration}</RateTypeStyle>
                                </RateBoxStyle>
                            </Box>
                        </ListMainBoxStyle>
                        <Box>
                            <DividerStyle />
                            <SkillsStyle>
                                {item.attributes.skills.map((skill, index) => (
                                    <SkillTagsStyle key={index}>{skill.name}</SkillTagsStyle>
                                ))}
                            </SkillsStyle>
                        </Box>
                    </ListMainBox>
                ))}
            </ServiceListStyle>
        </MainContainerService>
    )
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
        <ThemeProvider theme={theme}>
            {this.state.isLoader ? (
                <LoaderStyle>
                    <CircularProgress style={{ color: "#fff" }} />
                </LoaderStyle>
            ) : this.renderServiceList()}

        </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
