// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Search = require("../assets/Search.png");
export const close = require("../assets/close.png");
export const check = require("../assets/check.png");
export const check_img = require("../assets/check.png");
export const close_img = require("../assets/close.png");
export const left_img = require("../assets/left.png");
export const pdf_img = require("../assets/pdf.png");
export const search_img = require("../assets/Search.png");
export const Settings = require("../assets/Settings.png");
export const Insights = require("../assets/Insights.png");
export const ShareIcon = require("../assets/ShareBtn.png");
export const HeartIcon = require("../assets/HeartIcon.png");
export const EyeIcon = require("../assets/ShowIcon.png");
export const ListIcon = require("../assets/ListIcon.png");
export const rightArrow = require("../assets/MoreViewBtn.png");
export const SampleImg = require("../assets/Treesss.png");
export const Backbtn = require('../assets/BackBtn.png')
export const FollowBtn = require('../assets/followImg.png');
export const UnfollowBtn = require('../assets/UnfollowIcon.png');
export const userLogo = require("../assets/ic_userlogo.png");
export const filelink = require("../assets/filelink.png");
export const checkIcon = require("../assets/checkIcon.svg");
export const closeIcon = require("../../assets/closeIcon.svg");
export const dropDown = require("../../assets/dropDownIcon.png");
export const leftArrowIcon = require("../../assets/leftArrowIcon.svg");
export const addIcon = require("../assets/add.png")
export const removeIcon = require("../assets/remove.png")
export const dropDownIcon = require("../../assets/dropDownIcon.svg");
export const searchIcon = require("../../assets/search.png");
export const imgIcon = require("../../assets/imgIcon.png");
export const editIcon = require("../../assets/editIcon.svg");
export const attachmentIcon = require("../assets/Vector.png")
// Customizable Area End
