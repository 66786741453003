// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData} from "framework/src/Utilities";
import { ParticularCollection } from "../WorkExperience/WorkExperienceFormController";
import React, { RefObject } from "react";
import  { getCroppedImgData, getBase64Image, fileToDataURL, compressedImageData } from "../../../../components/src/Base64Conversion";

interface ListType {
  id: number,
  name: string
}
interface ListFileType {
  data: any
  filename: string,
  file: File
}
type State = {
  minValue: string;
  serviceName: string;
  maxValue: string;
};
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCategoryOpen: boolean,
  isMinTimeLineOpen:boolean,
  croppedImage: string[],
  isCollectionOpen: boolean,
  category:ListType
  categoryList: ListType[],
  timeLineTypeList:string[]
  timeLineList:number[]
  minTimeLineError:string
  maxTimeLine:number | null
  minTimeLine:number | null
  minRateError:string
  timeLineType:string
  selectedSkillTags: ListType[],
  skillList: ListType[],
  searchText: string,
  searchSkillList: ListType[],
  collectionList:any;
  currentCollectionId:any;
  currentPage:number;
  particularCollectionData:ParticularCollection[],
  tempSelectedProjectList: string[],
  tempSelectedCover:number
  selectedProjectList: ParticularCollection[],
  previewData:any
  totalCount:number
  coverImage: File[]
  uploadedCoverImage: File[]
  open:boolean
  message: string,
  showCropper:boolean,
  action:string
  coverImageText:string
  isFileLoading:boolean
  profileImage:ListFileType[]
  loadedImage: number
  crop: { x: number; y: number };
  zoom: number;
  croppedAreaPixels: {
    x: number;
    y: number;
    width: number;
    height: number;
  } | null;
  deliverables:string[]
  links:string[]
  currentLink: string
  description:string
  descCountCharacter:number
  minValue:string
  maxValue:string
  serviceName:string
  isMaxTimeLineOpen:boolean
  isTimeLineTypeOpen:boolean
  selectedLocation: string,
  locationError:string,
  postOpportunityLoader: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddServiceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  delayTimer: any;
  collectionDataID:string='';
  collectionNameGetApiCallId:string='';
  apiSearchSkillsCallId:string=''
  apiSkillsCallId:string=''
  addServiceCallId:string= ''
  baseUrlString:string = configJSON.baseURL.baseURL;
  refElm: RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      uploadedCoverImage:[],
      postOpportunityLoader: false,
      timeLineTypeList: ["Months", "Weeks"],
      timeLineList: Array.from({ length: 12 }, (_, i) => i + 1),
      maxTimeLine: null,
      minTimeLine: null,
      minRateError: "",
      isMaxTimeLineOpen: false,
      isTimeLineTypeOpen: false,
      timeLineType: "Months",
      selectedLocation: "",
      locationError: "",
      serviceName: "",
      minValue: "",
      maxValue: "",
      deliverables: [],
      descCountCharacter: 0,
      currentLink: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      open: false,
      totalCount: 0,
      croppedImage: [],
      isCategoryOpen: false,
      minTimeLineError: "",
      isMinTimeLineOpen: false,
      isCollectionOpen: false,
      category: { id: 2, name: "Location" },
      categoryList: [{ id: 1, name: "Remote / Anywhere" }, { id: 2, name: "Location" }],
      selectedSkillTags: [],
      skillList: [],
      searchText: "",
      loadedImage: 0,
      searchSkillList: [],
      collectionList: [],
      currentCollectionId: 0,
      currentPage: 0,
      particularCollectionData: [],
      tempSelectedProjectList: [],
      tempSelectedCover: 0,
      selectedProjectList: [],
      previewData: [],
      coverImage: [],
      message: "",
      action: "",
      coverImageText: '',
      showCropper: false,
      isFileLoading: false,
      profileImage: [],
      croppedAreaPixels: null,
      links: [],
      description: ""
    };
    this.refElm = React.createRef<HTMLInputElement>();
    this.delayTimer = null;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId1 === this.collectionDataID) {
      this.setState({
        particularCollectionData:responseJson1?.data,
        totalCount:Math.ceil(responseJson1?.meta?.project_count/6)
      });
    }
    if (apiRequestCallId1 === this.collectionNameGetApiCallId) {
     this.handleResponseGetCollection(responseJson1)
    }
    if (apiRequestCallId1 === this.apiSearchSkillsCallId) {
      this.handleResponseSearchSkills(responseJson1)
    }
    if(apiRequestCallId1 === this.apiSkillsCallId){
      if (!responseJson1.errors) {
        const dataVal = responseJson1.data || '';
        if (dataVal) {
          this.setState({ skillList: [...dataVal.map((item: { id: string | number, name: string }) => ({ id: item.id, name: item.name }))] });
        }
      } 
    }
    if(apiRequestCallId1 === this.addServiceCallId){
      if (!responseJson1.errors) {
        this.setState({
          open: true,
          message: "Service created successfully",
          action: "success"
        })
        this.redirectTo("UserProfile")
      } 
    }

    

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   this.addDeliverable()
   this.getCollectionAPI()
   this.getSkillsList()
  }

  handleDragOver = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };
  enableButtonText = () => {
    return this.enablePostWorkOpportunity() ? "secondary-btn" : "disabled-btn";
  }
  handleResponseGetCollection = (responseJson1:any) => {
    if (responseJson1?.data?.collections) {
      this.setState({
        collectionList: responseJson1.data.collections,
        currentCollectionId: responseJson1.data.collections[0].id
      }, () => {
        this.collectionData(responseJson1.data.collections[0].id); 
      });
    }
  }
  handleResponseSearchSkills = (responseJson1:any) =>{
    if (!responseJson1.errors) {
      const dataVal = responseJson1.data || '';
      if (dataVal) {
        const searchSkillList = dataVal.map((item: { id: string | number, name: string }) => ({
          id: item.id,
          name: item.name,
        }));
        const { selectedSkillTags } = this.state; 
        const updatedSearchSkillList = searchSkillList.filter(
          (skill: { name: string; }) => !selectedSkillTags.some((selected) => selected.name === skill.name)
        );
        this.setState({ searchSkillList: updatedSearchSkillList });
      }
    }
  }
  handleMinMaxValidationRate = () => {
    const { maxValue, minValue } = this.state;
    const minRate = parseFloat(minValue);
    const maxRate = parseFloat(maxValue);
    if (!isNaN(minRate) && !isNaN(maxRate) && minRate > maxRate) {
        this.setState({ minRateError: "Minimum rate cannot be greater than Maximum rate" });
    } else {
        this.setState({ minRateError: "" });
    }
};
  handleInputChange = <K extends keyof State>(
  field: K,
  value: State[K]
) => {
  this.setState({ [field]: value } as Pick<State, K>,this.handleMinMaxValidationRate);
};
  handleDeliverablesChange = (index: number, value: string) => {
    const newDeliverables = [...this.state.deliverables];
    newDeliverables[index] = value;
    this.setState({ deliverables: newDeliverables });
};
  handleLinkChange = (value: string) => {
    this.setState({ currentLink: value });
  }
  isUrlValid = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      this.setState({
        open: true,
        message: "Please enter valid link",
        action: "danger"
      })
      return false;
    }
  }
  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  backAddService = () =>{
    this.redirectTo("UserProfile")
  }
  handleAddLink = () => {
    const { links, currentLink } = this.state;
    if(links.includes(currentLink.trim())){
      this.setState({
        open: true,
        message: "Link already added",
        action: "danger"
      }) 
    }
    if (currentLink.trim() && !links.includes(currentLink.trim()) && this.isUrlValid(this.state.currentLink)) {
      this.setState((prevState) => ({
        links: [...prevState.links, currentLink.trim()],
        currentLink: "",
      }));
    }
  };
  handleChangeLocationText = (data: string) => {
    this.setState({ selectedLocation: data})
  };
  handleChangeLocation = (data: string) => {
    this.setState({selectedLocation:data.split(",")[0]})
  };
  handleInputChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      event.target.value = value;
    } else {
      event.preventDefault();
      event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
    }
  };
addDeliverable = () => {
    this.setState(prevState => ({
        deliverables: [...prevState.deliverables, ''],
    }));
};
removeDeliverable = (index: number) => {
  const newDeliverables = this.state.deliverables.filter((_, i) => i !== index);
  this.setState({ deliverables: newDeliverables });
};
handleDescriptionChange = (data: string) => {
  this.setState({ description: data })
}
handleCountCharacter = (e: string | any[]) =>{
  const characterCount = typeof e === 'string' ? e.length : 0;
  this.setState({ descCountCharacter: characterCount });
}

  closePopup = () => {
    this.setState({showCropper: false});
  }
  handleDiscardCroppedImage = () => {
    this.setState({ coverImage: [], zoom: 1, showCropper: false });
  };
  handleCropChange = (crop: { x: number; y: number }) => {
    this.setState({ crop });
  };
  onCropComplete = (croppedArea: any, croppedAreaPixel: any) => {
    this.setState({ croppedAreaPixels: croppedAreaPixel });
  };
  handleZoomChangeFromSlider = (event: React.ChangeEvent<{}>, value: number | number[] ) => {
    this.setState({ zoom: value as number });
  };
  handleZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };
  scrollToSection = (sectionTopIdName:string) => {
    const container = document.getElementById(sectionTopIdName); 
    if (container) {
        container.scrollTo({
            left: container.scrollWidth,
            behavior: 'smooth',
        });
    }
};
  handleCroppedImage = async () => {
    if (this.state.coverImage.length) {
      const dataUrl:any = await fileToDataURL(this.state.coverImage[0]);
      const resData:any = await getCroppedImgData(dataUrl, this.state.croppedAreaPixels);
      const imageData = await fileToDataURL(resData);
      this.setState((prevState):any => ({
        uploadedCoverImage:[...prevState.uploadedCoverImage,this.state.coverImage[0]],
        showCropper: false,
        zoom: 1,
        croppedImage: [...prevState.croppedImage,imageData],
        coverImage: [],
      }));
      this.scrollToLastElement()
    }
  };

  validFileFormat = (fileData: File) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validImageTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid image file (jpg, png).",
        action: "danger"
      });
      return false;
    }
    return true
  }
  handleDropFile = (size:number) =>{
    const maxFileSizeMB = 2;
      if (size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
      }
    }
    loadedData = (loaded: number) => {
      this.setState({ loadedImage: loaded });
    }
  handleDrop = async (event: any, fieldData?:string) => {

    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if(!this.validFileFormat(file)) {
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      this.handleDropFile(file.size)
      const compressedFile = await compressedImageData(file, 0, 0, 0.7);
      if (fieldData) {
        this.setState({ coverImage: [compressedFile], showCropper: true, coverImageText:  compressedFile.name});
        this.setState((prevState):any => ({
          uploadedCoverImage:[...prevState.uploadedCoverImage,compressedFile]
        }));
      } else {
        this.setState({ 
          isFileLoading: true, 
          profileImage: [...this.state.profileImage, {data: "", filename: file.name, file: compressedFile}] 
        });
        const urlData: any = await fileToDataURL(compressedFile);
        await getBase64Image(urlData, this.loadedData);
        this.setState({
          isFileLoading: false,
          profileImage: [...this.state.profileImage.filter(item => item.data), {data: urlData || '', filename: file.name, file: compressedFile}]
        });
      }
      if(this.refElm.current) {
        this.refElm.current.value = '';
      }
    }
  };
  handleUploadImageClick = () =>{
    if(this.refElm.current !== null){
      this.refElm.current.click() 
    };
    
  }
  scrollToLastElement = () => {
    const container = document.getElementById('image-upload-id');
    if (container) {       
        container.scrollTo({
            left: container.scrollWidth,
            behavior: 'smooth',
        });
    }
};
  handleCoverImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    
    this.setState({ coverImage: [], showCropper: false });
    if (event.target.files) {
      const tempFile = event.target.files[0];
      if (!this.validFileFormat(tempFile)) {
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const maxFileSizeMB = 2;
      if (tempFile.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const compressedFile = await compressedImageData(tempFile, 0, 0, 0.7);
      this.setState({
        coverImage: [compressedFile],
        showCropper: true,
        coverImageText:  compressedFile.name
      });
      if(this.refElm.current) {
        this.refElm.current.value = '';
      }
    }
  }
  toggleCategoryMenu = (event: any) => {
    this.setState( { isCategoryOpen : !this.state.isCategoryOpen, isCollectionOpen: false,isMaxTimeLineOpen:false,isMinTimeLineOpen:false,isTimeLineTypeOpen:false  });
    event.stopPropagation();
  }
  toggleTimeLineMenu = (event: { stopPropagation: () => void; }) =>{
    this.setState( { isMaxTimeLineOpen:false,
      isMinTimeLineOpen:!this.state.isMinTimeLineOpen,
      isCategoryOpen : false,
      isTimeLineTypeOpen:false, 
      isCollectionOpen: false});
    event.stopPropagation();
  }
  toggleMaxTimeLineMenu = (event: { stopPropagation: () => void; }) =>{
    this.setState( { isMaxTimeLineOpen:!this.state.isMaxTimeLineOpen,
      isMinTimeLineOpen:false,
      isCategoryOpen : false, 
      isCollectionOpen: false,
      isTimeLineTypeOpen:false
    });
    event.stopPropagation();
  }
  toggleTimeLineTypeMenu = (event: { stopPropagation: () => void; }) =>{
    this.setState( { isTimeLineTypeOpen:!this.state.isTimeLineTypeOpen,
      isMaxTimeLineOpen:false,
      isMinTimeLineOpen:false,
      isCategoryOpen : false, 
      isCollectionOpen: false});
    event.stopPropagation();
  }
  handleCategory = (data: any) => {
    this.setState({
        selectedLocation:"",
        isCategoryOpen: false,
        category: data,
    })
  }
  handleMinMaxValidation = () => {
    const { minTimeLine, maxTimeLine } = this.state;
    if (minTimeLine !== null && maxTimeLine !== null && minTimeLine > maxTimeLine) {
      this.setState({ minTimeLineError: "Minimum value cannot be greater than Maximum value" });
    } else {
      this.setState({ minTimeLineError: "" });
    }
  };
  handleTimeLine = (data: any) => {
    this.setState(
      {
        isMinTimeLineOpen: false,
        minTimeLine: data,
      },
      this.handleMinMaxValidation 
    );
  };
  handleMaxTimeLine = (data: any) =>{
    this.setState({
      isMaxTimeLineOpen:false,
      maxTimeLine:data
    },this.handleMinMaxValidation 
  )
  }
  handleMaxRate = (data:string) => {
    if(parseFloat(data)>5000){
      this.setState({
        minRateError:"Maximum Rate not grater then 5000"
      })
    }else{
    this.setState({
      maxValue:data
    },this.handleMinMaxValidationRate )}
  }
  handleMinRate = (data:string) => {
    if(parseFloat(data)>5000){
      this.setState({
        minRateError:"Maximum Rate not grater then 5000"
      })
    }else{
    this.setState({
      minValue:data
    },this.handleMinMaxValidationRate )}
  }
  handleTimeLineType = (data :any) =>{
    this.setState({
      isTimeLineTypeOpen:false,
      timeLineType:data,
      timeLineList:Array.from({ length: data === "Months"?12:52 }, (_, i) => i + 1),
      minTimeLineError:"",
      minTimeLine:null,
      maxTimeLine:null
    })
  }
  isSkillAdded = () => {
    return this.state.selectedSkillTags.length > 0 ? true : false;
  }
  handleRemoveSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: this.state.selectedSkillTags.filter(item => item.id !== data.id), skillList: [data, ...this.state.skillList] });
  }
  handleClassess = () => {
    return this.state.selectedSkillTags.length ? "search-field main-text" : "search-field main-text h-63";
  }
  handleCancel = () =>{
    this.setState({ searchText: "",searchSkillList:[] })
  }
  handleSkillView = () => {
    return (this.state.searchText.trim() && this.state.searchSkillList.length) || (!this.state.searchText.trim() && this.state.skillList.length);
  }
  handleSkillORSearchList = () => {
    return this.state.searchSkillList.length ? this.state.searchSkillList : this.state.skillList;
  }
  handleAddSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: [data, ...this.state.selectedSkillTags], skillList: this.state.skillList.filter(item => item.id !== data.id),searchSkillList:this.state.searchSkillList.filter(item => item.name !== data.name) });
  }
  handleCollection=(id:number|null)=>{
    this.setState({currentCollectionId:id,currentPage:1})
    this.collectionData(id)
  }
  hidemenu = () => {
    this.setState({
      isCategoryOpen: false,
      isCollectionOpen: false,
      isMaxTimeLineOpen:false,
      isMinTimeLineOpen:false,
      isTimeLineTypeOpen:false
    });
  }
  collectionData= async (collectionId:number|null)=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const { meta: { id } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCollectionEndPoint}/get_projects_based_collection?collection_id=${collectionId}&account_id=${id}&page=${this.state.currentPage}&per_page=6`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.collectionDataID = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getSkillsList= async ()=>{
    const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;    
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkillEndPoint)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.apiSkillsCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getSearchSkillsList= async (data:string)=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;     
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchSkillEndPoint}?search_term=${data}`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.apiSearchSkillsCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getCollectionAPI = async () => {
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserCollectionEndPoint )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.collectionNameGetApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  handleSelectAll = () => {
    const { particularCollectionData, tempSelectedProjectList, selectedProjectList } = this.state;
  
    if (this.areAllProjectsSelected()) {
      const updatedTempList = tempSelectedProjectList.filter(id => !particularCollectionData.some(item => item.id === id));
      const updatedSelectedList = selectedProjectList.filter(project => !particularCollectionData.some(item => item.id === project.id));
  
      const groupedProjects = updatedSelectedList.reduce((acc: any, project) => {
        const collectionName = project.attributes.collection_name;
        if (!acc[collectionName]) {
          acc[collectionName] = {
            collection_name: collectionName,
            data: [],
          };
        }
        acc[collectionName].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        tempSelectedProjectList: updatedTempList,
        selectedProjectList: updatedSelectedList,
        previewData: Object.values(groupedProjects),
      });
    } else {
      const newIds = particularCollectionData.map(item => item.id);
      const updatedSelectedList = [...new Set([...selectedProjectList, ...particularCollectionData])];
      const updatedTempList = [...new Set([...tempSelectedProjectList, ...newIds])];
  
      const groupedProjectsData = updatedSelectedList.reduce((acc: any, project) => {
        const collectionNameData = project.attributes.collection_name;
        if (!acc[collectionNameData]) {
          acc[collectionNameData] = {
            data: [],
            collection_name: collectionNameData,
          };
        }
        acc[collectionNameData].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        previewData: Object.values(groupedProjectsData), 
        selectedProjectList: updatedSelectedList,
        tempSelectedProjectList: updatedTempList,
      });
    }
  };
  areAllProjectsSelected = () => {
    const { particularCollectionData, tempSelectedProjectList } = this.state;
    return particularCollectionData&& particularCollectionData.length>0 ? 
    particularCollectionData.every(item => tempSelectedProjectList.includes(item.id)):
    false;
  };
  handleSelectCover = (index:number) =>{
    this.setState({tempSelectedCover:index})
  }

  handleareAllProjectsSelected=()=>{
    return this.areAllProjectsSelected() ? "Deselect all" : "Select all"
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  toggleSelectProject = (item: ParticularCollection) => {
    const { tempSelectedProjectList, selectedProjectList } = this.state;
    const isAlreadySelected = tempSelectedProjectList.includes(item.id);
  
    let updatedTempList, updatedSelectedList;
  
    if (isAlreadySelected) {
      updatedTempList = tempSelectedProjectList.filter(projectId => projectId !== item.id);
      updatedSelectedList = selectedProjectList.filter(project => project.id !== item.id);
    } else {
      updatedTempList = [...tempSelectedProjectList, item.id];
      updatedSelectedList = [...selectedProjectList, item];
    }
  
    const groupedProjectsParticular = updatedSelectedList.reduce((acc: any, project) => {
      const collectionNameParticular = project.attributes.collection_name;
      if (!acc[collectionNameParticular]) {
        acc[collectionNameParticular] = {
          data: [],
          collection_name: collectionNameParticular,
        };
      }
      acc[collectionNameParticular].data.push(project.attributes);
      return acc;
    }, {});
  
    this.setState({
      selectedProjectList: updatedSelectedList,
      previewData: Object.values(groupedProjectsParticular), 
      tempSelectedProjectList: updatedTempList,
    });
  };
  handleChanges=(event:any,page: any)=>{
    this.setState({currentPage:page})
  }
  search = (data: string) => {
    this.getSearchSkillsList(data)
  };

  handleChange = (data: string) => {
    this.setState({ searchText: data });
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      if (data.trim() !== "" && data.trim().length >= 2) {
        this.search(data.trim());
      } else {
        this.setState({ searchSkillList: [] })
      }
    }, 300);
  };
  enablePostWorkOpportunity = () => {
    const {
        description,
        selectedSkillTags,
        coverImage,
        timeLineType,
        minTimeLine,
        maxTimeLine,
        minValue,
        maxValue,
        deliverables,
        serviceName,
        minTimeLineError,
        minRateError,
        category,
        selectedLocation,
    } = this.state;

    if (
        description &&
        selectedSkillTags.length &&
        coverImage &&
        timeLineType &&
        minTimeLine &&
        maxTimeLine &&
        minValue &&
        maxValue &&
        deliverables.length &&
        serviceName &&
        !minTimeLineError &&
        !minRateError &&
        (!category || category.name !== "Location" || selectedLocation)
    ) {
        return true;
    }
    return false;
};

  handleModelOpen = async () => {

    this.setState({postOpportunityLoader:true})
    
      const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
      const { meta: { token } } = newToken;   
      const headers = {
        token: token,
      };
    const formData = new FormData();
    const skill_ids = this.state.selectedSkillTags.map(skill => skill.id)
    const project_ids = this.state.selectedProjectList.map(project => project.id)
    const links = this.state.links.map(link=>link)
    formData.append("service[service_name]", this.state.serviceName)
    formData.append("service[description]", this.state.description)
    formData.append("service[duration]", this.state.timeLineType);
    formData.append("service[work_setting]", this.state.category.name)
    formData.append("service[city]", this.state.selectedLocation)
    formData.append("service[minimum_rate]", this.state.minValue)
    formData.append("service[maximum_rate]", this.state.maxValue)
    formData.append("service[skill_ids]",skill_ids.join(","))
    formData.append("service[project_ids]",project_ids.join(","))
    formData.append("service[links]",links.join(","))
    formData.append("service[min_timeline]",this.state.minTimeLine? this.state.minTimeLine.toString(): "")
    formData.append("service[max_timeline]",this.state.maxTimeLine? this.state.maxTimeLine.toString():"")
    this.state.deliverables.forEach((item: string | null, index: number) => {
      if (item !== "" && item !== undefined) {
        formData.append(`service[service_deliverables_attributes][${index}][deliverable]`, item ? item.toString():"");
      }
    });
    formData.append("service[cover_image]",this.state.uploadedCoverImage[this.state.tempSelectedCover])
    this.state.uploadedCoverImage.length > 1 && this.state.uploadedCoverImage.map((item, index) => {
      if (index !== this.state.tempSelectedCover) {
        formData.append("service[service_images][]", item);
      }
    });
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.AddServiceEndPoint)
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      this.addServiceCallId = getValidationsMsg.messageId
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  // Customizable Area End
}
