import {
  withStyles,
  Switch
} from "@material-ui/core";

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    padding: 0,
    height: 26,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#8C8C8C',
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#757575',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#4f4f4f',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);